!<template>
  <div>
    <v-container>
      <v-row class="d-flex flex-column my-16">
        <div class="aos-item" data-aos="fade-down" data-aos-duration="650">
          <v-col class="d-flex justify-center">
            <h3 class="ttext-h3 oswald  second-color">LESSON TYPES </h3>
          </v-col>
          <v-col class="d-flex justify-center text-center">
            <p class="mx-5 ttext-h4 dancing white--text">improve your skills!</p>
          </v-col>
          <v-col class="d-flex justify-center text-center">
            <p class="px-16 mx-16">
              Don’t stick to the one type of lesson. Take advantage of the options provided. We developed Masterstudy LMS with a well-defined approach in mind. And the main point of this was to make it diverse.
           </p>
          </v-col>
        </div>
        <v-row class="d-flex flex-row justify-center my-10">
         <v-col class="d-flex flex-column text-center mx-3 aos-item"
          data-aos="fade-up-left" data-aos-duration="650">
            <v-icon
              color="red"
              x-large>
              mdi mdi-video-outline
                   </v-icon>
             <p>VIDEO</p>
            </v-col>
         <v-col class="d-flex flex-column text-center mx-3 aos-item"
          data-aos="fade-up-left" data-aos-duration="650">
            <v-icon
              color="blue"
              x-large>
              mdi mdi-text-box-outline
                   </v-icon>
                   <p>TEXT</p>
            </v-col>
         <v-col class="d-flex flex-column text-center mx-3 aos-item"
         data-aos="fade-up-right" data-aos-duration="650">
            <v-icon
            class=""
              color="orange"
              x-large>
              mdi mdi-microsoft-powerpoint
                </v-icon>
                  <p>SLIDES</p>
            </v-col>
         <v-col class="d-flex flex-column text-center mx-3 aos-item"
         data-aos="fade-up-right" data-aos-duration="650">
            <v-icon
              color="green"
              x-large>
              mdi mdi-view-stream-outline
                </v-icon>
                  <p>STREAMING</p>
            </v-col>
          </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>

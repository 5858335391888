import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#3f51b5', // Customize the primary color
        secondary: '#b0bec5', // Customize the secondary color
        accent: '#8c9eff' // Customize the accent color
      }
    }
  }
})

<template>
  <div>
      <hello-world />
      <second-com/>
      <lesson-types/>
      <parallax-img-2/>
      <quizz-com/>

  </div>
</template>

<script>
import SecondCom from '@/components/SecondCom.vue'
import HelloWorld from '../components/HelloWorld'
import LessonTypes from '@/components/LessonTypes.vue'
import QuizzCom from '@/components/QuizzCom.vue'
import ParallaxImg2 from '@/components/ParallaxImg2.vue'

export default {
  name: 'home-view',

  components: {
    HelloWorld,
    SecondCom,
    LessonTypes,
    QuizzCom,
    ParallaxImg2
  }
}
</script>

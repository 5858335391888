!<template>
  <v-container>
    <v-row class="d-flex flex-column my-16">
        <v-col class="d-flex justify-center">
          <h3 class="ttext-h3 oswald  second-color">ADVANCED</h3>
        </v-col>
        <v-col class="d-flex justify-center text-center">
          <p class="mx-5 ttext-h4 dancing white--text">Quizzes!</p>
        </v-col>
        <v-col class="d-flex justify-center text-center">
          <p class="px-16 mx-16">
            Advanced Quizzes options allow you to create interesting quiz samples. Strengthen the learning and make up manually graded tests and exams with different question types.
         </p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6 aos-item" data-aos="zoom-in" data-aos-duration="650">
      <v-col
      v-for="(quizz,j) in quizzes"
      :key="j"
        class="pa-2"
        outlined
        tile
        cols="12"
        md="4"
      >
      <img :src=quizz.src alt="" width="350px" class="rounded-pill">
      <p class="text-center text-subtitle-2">
        {{ quizz.subtitle }}
      </p>
      </v-col>
      </v-row>
      <v-row class="d-flex justify-space-around align-center mb-6">
      <v-col
        v-for="(check,n) in checkes"
        :key="n"
        class="pa-2"
        outlined
        tile
        cols="12"
        md="4"
      >
      <v-row class="d-flex flex-row align-center my-2">
        <v-icon
              color="#c64d6a"
              x-large>
              mdi mdi-check-circle-outline
                </v-icon>
            <p class="my-0 mx-2">{{check.subtitle}}</p>
      </v-row>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      quizzes: [
        {
          src: 'https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/home-page/advanced-modules/learning_management_system_wordpress.jpg',
          subtitle: 'RADIOBUTTON'
        },
        {
          src: 'https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/home-page/advanced-modules/best_lms_for_wordpress.jpg',
          subtitle: 'MATCH'
        },
        {
          src: 'https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/home-page/advanced-modules/learning_management_system_for_wordpress.jpg',
          subtitle: 'MULTI SELECT'
        },
        {
          src: 'https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/home-page/advanced-modules/learning_management_system_in_wordpress.jpg',
          subtitle: 'FILL THE GAP'
        },
        {
          src: 'https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/advanced_quizzes/quiz-6.jpg',
          subtitle: 'TRUE/FALSE'
        },
        {
          src: 'https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/advanced_quizzes/quiz-5.jpg',
          subtitle: 'KEYWORDS'
        }
      ],
      checkes: [
        {
          subtitle: 'Create categories with Question Bank'
        },
        {
          subtitle: 'Automatically randomize questions'
        },
        {
          subtitle: 'Set up automated quiz results'
        },
        {
          subtitle: 'Allow students to retake quizzes'
        },
        {
          subtitle: 'Attach media files of different types'
        },
        {
          subtitle: 'Set timers for taking quizzes'
        }
      ]

    }
  }
}
</script>

<style>

</style>

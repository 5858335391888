<template>
  <v-app class="all-courses">
    <div class="blob"></div>

    <v-main class="all-courses">
      <router-view/>
    </v-main>
    <v-footer
      padless
      height="300"
      class=" d-flex align-end all-courses"
    >
    <svg width="1400" height="300" class="svg-div-2">
  <polygon points="0,0 00,0 3000,300 0,300" fill="#c64d6a" />
</svg>
      <v-container >
        <v-row
          justify="center"
          align-content="end"
          align="end"
          class="d-flex text-center"
        >
          <v-col
            cols="12"
            md="4"
            class="pa-0"
          >
            <v-list-item
              v-vue-aos="{animationClass:'animate__fadeInRight animate__animated'}"
            >
           <img src="@/assets/logoDownName.png" width="150" alt="">
            </v-list-item>
            <v-list-item>
                <v-list-item-title class="text-left text--secondary text-body-1">
                  Users and submit their own items. You can <br> create different packages and by <br> connecting with your PayPal <br> or Stripe account charge users for registration.
              </v-list-item-title>
              </v-list-item>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pa-0"
          >
            <div
              v-vue-aos="{animationClass:'animate__fadeInUp animate__animated'}"
            >
              <v-list-item class=" font-weight-bold Ysabeau text-left ">
                popular news
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="text-left text--secondary text-body-1">
                Subscribe to get special offers, <br> free gifts and once-in-a-lifetime deals.
              </v-list-item-title>
              </v-list-item>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pa-0"
          >
            <div
              v-vue-aos="{animationClass:'animate__fadeInLeft animate__animated'}"
            >
              <v-row>
                <v-col>
                  <v-hover v-slot="{hover}">
                    <v-btn
                    class=" magic-hover magic-hover__square"
                    elevation="2"
                    x-large
                    rounded
                    :outlined="!hover"
                    color="#8799e1"
                    :to="{name: 'all-courses'}"
                    >
                     See all courses
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-card
        flat
        tile
        class=" white--text "
        width="100%"
        min-height="20%"
        style=" background-color: #C64D6A;"
      >
        <v-row
          class="text-center"
          align="end"
          align-content="end"
        >
          <v-col
            cols="12"
            md="3"
            align-self="end"
            class="mt-6"
          >
            &copy; {{ new Date().getFullYear() }} — <strong>clever course. All rights reserved</strong>
          </v-col>
         <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="3"
            style=" color:#fff"
          >
            <strong> privacy policy </strong>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style>
.blob{
  width: 440px;
    position: fixed;
    z-index: -100000000;
    bottom: 0;
    right: 10%;
    opacity: .3;
    height: 440px;
    border-radius: 15rem;
    background: radial-gradient(77.73% 77.73% at 98.52% 96.25%,#007aff 0,#007aff 53.91%,#fd2c5e 100%),linear-gradient(153.07deg,#007aff 6.37%,rgba(255,230,166,0) 83.82%);
    background-blend-mode: normal,normal;
    filter: blur(100px);
    transform-origin: 50% 50%;
}
.background{
  background-color: #04101a !important;
}
.svg-div-2{
    position: absolute;
    z-index: 0;
  }
  .all-courses{
  background-image: url(@/assets/abstract-technology-mobile-wallpaper-connecting-dots-borders.jpg);
  background-size: cover;
  background-position: center;
}
</style>

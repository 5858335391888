!<template>
  <div>
    <v-row class="d-flex flex-column my-16">
      <v-col
       class="d-flex justify-center mt-16 aos-item"
       data-aos="fade-up" data-aos-duration="650">
        <h1 class="ttext-h1-1 londrina text--secondary mb-16">
          S<span class="down">U</span>B<span class="down">S</span>C<span class="down">R</span>I<span class="down">B</span>E
        </h1>
      </v-col>
      <div class="aos-item" data-aos="fade-down" data-aos-duration="650">
        <v-col class="d-flex justify-center">
          <h3 class="ttext-h3 oswald  second-color">Free & Full educational courses <span class="mx-5 ttext-h4 dancing white--text">improve your skills!</span></h3>
        </v-col>
        <v-col class="d-flex justify-center text-center">
        <p class="px-16 mx-16 text--secondary">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab laudantium error deleniti a non dicta modi numquam. <br> Unde ex natus consequatur laborum, temporibus deleniti quae, sit nesciunt, perspiciatis doloribus labore!
          Lorem ipsum dolor sit amet consectetur adipisicing elit.<br> Ab laudantium error deleniti a non dicta modi numquam.  Unde ex natus consequatur laborum, temporibus deleniti quae, sit nesciunt, perspiciatis doloribus labore!
        </p>
        </v-col>
        <v-col class="d-flex justify-center text-center">
          <p class="px-16 mx-16">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique et molestias voluptatibus si
        </p>
        </v-col>
      </div>
      <v-col class="d-flex justify-center text-center">
       <img src="https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/home-page/community/community-bg.png" style="width: 65%;" alt="">
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.ttext-h1-1{
  font-size: 5rem !important;
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: 0.984375em !important;
}
.down{
  position: relative; top: 47px;
}
</style>

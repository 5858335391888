<template>
  <div  class="my-component"
   style="background-image: url('https://static.wixstatic.com/media/c837a6_2119733e838e4a2f8813ebde736f99d5~mv2.jpg/v1/fill/w_1269,h_1300,al_b,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_2119733e838e4a2f8813ebde736f99d5~mv2.jpg');">
   <v-app-bar
    flat
    color="transparent">
        <div class="d-flex align-center">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../assets/loGo-removebg-preview.png"
            transition="scale-transition"
            width="80"
          />
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="200"
            src="../assets/logoName.png"
            width="200"
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
        :to="{name: 'home-view'}"
          text
        >
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn
        :to="{name: 'about-view'}"
          text
        >
          <span class="mr-2">About Us</span>
        </v-btn>
        <v-btn
        :to="{name: 'all-courses'}"
          text
        >
          <span class="mr-2">Courses</span>
        </v-btn>
    </v-app-bar>
    <div>
    <!-- Your page content -->

    <v-scroll-x
      v-if="showScrollButton"
      bottom
      right
    >
      <v-btn
        fab
        dark
        color="primary"
        small
        @click="scrollToTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scroll-x>
  </div>
   <v-row class="d-flex justify-start align-center mx-16 my-16">
    <v-col cols="12" md="9">
      <h1 class="my-5 ttext-h3 oswald first-color">Clever Course</h1>
      <h1 class="ttext-h1 oswald first-color">ONLINE Courses</h1>
      <h1 class="ttext-h4 dancing second-color">improve your skills</h1>
      <h1 class="my-9 subtitle-2 text--secondary">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quos aspernatur necessitatibus nostrum tenetur dolorem, magnam eligendi tempora fugit. In voluptate reiciendis, similique animi rerum iste cum quibusdam minima placeat.</h1>
    </v-col>
    </v-row>
    <v-row class="mx-16 mt-16">
      <v-col class="d-flex justify-end mx-16">
        <v-hover v-slot="{hover}">
        <v-btn
        class=" magic-hover magic-hover__square"
        elevation="2"
        x-large
        color="#c64d6a"
        rounded
        :outlined="!hover"
        :to="{name: 'all-courses'}">
         SEE COURSES
        </v-btn>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  data () {
    return {
      showScrollButton: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      // Show the scroll button when the user has scrolled down the page
      this.showScrollButton = window.pageYOffset > 100
    },
    scrollToTop () {
      // Scroll the page to the top
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Londrina+Shadow&display=swap');
.my-component {
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.oswald{
  font-family: 'Oswald', sans-serif !important;
}
.dancing{
  font-family: 'Dancing Script', cursive !important;
}
.londrina{
  font-family: 'Londrina Shadow', cursive !important;
}
.ttext-h3{
  font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
}
.ttext-h1{
  font-size: 6rem !important;
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: -0.015625em !important;
}
.ttext-h4{
  font-size: 3rem !important;
    font-weight: 400;
    line-height: .5rem;
    letter-spacing: 0.0073529412em !important;
}
.first-color{
  color: #8799e1;
}
.second-color{
  color: #c64d6a;
}
</style>

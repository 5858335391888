!<template>
  <v-parallax
height="500"
src="https://stylemixthemes.com/masterstudy/wp-content/themes/landing/assets/images/home-page/udemy/udemy.jpg">
</v-parallax>
</template>

<script>
export default {

}
</script>

<style>

</style>
